import React from 'react'
import BlogGrid from '../components/BlogGrid'
import Navbar1 from '../components/Navbar1'

function BlogGrid1() {
  return (
    <>
        <Navbar1/>
        <BlogGrid />
    </>
  )
}

export default BlogGrid1