import React from 'react'
import BlogDetails from '../components/BlogDetails'
import Navbar1 from '../components/Navbar1'

function BlogDetails1() {
  return (
    <>
        <Navbar1/>
        <BlogDetails />
    </>
  )
}

export default BlogDetails1