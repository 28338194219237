import React from 'react'
import Contacts from '../components/Contacts'
import Navbar1 from '../components/Navbar1'

function Contacts1() {
  return (
    <>
        <Navbar1 />
        <Contacts />
    </>
  )
}

export default Contacts1