import React from "react";
import Facts from "../components/Facts";
import Navbar1 from "../components/Navbar1";
import Services from "../components/Services";
import Testimonial from "../components/Testimonial";

function Services1() {
  return (
    <>
      <Navbar1 />
      <Services />
      <Facts />
      <Testimonial />
    </>
  );
}

export default Services1;
